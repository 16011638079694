import React from "react"
import { Container, Box, Flex } from "theme-ui"
import { FooterMenu } from "./Footer.Menu"
import { Copyrights } from "../../../components/Copyrights/Copyrights"
import "./Footer.css"

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
  }
}


export const Footer = () => {
  
  return (
    <Box sx={styles.wrapper}>
      <Container variant="compact" sx={styles.container}>
        <Flex className='footer' variant="layout.footer">
          <FooterMenu />
        </Flex>
        <Copyrights />
      </Container>
    </Box>
  )
}
