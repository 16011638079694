import React from 'react'
import { Box } from 'theme-ui'
import Navigation from '@components/Navigation'
import Drawer from '@components/Drawer'
import useSiteMetadata from '@helpers/useSiteMetadata'

const styles = {
  desktopMenu: {    
    display: [`none`, `none`, `block`]
  },
  mobileMenu: {
    display: [`block`, `block`, `none`]
  },
  desktopMenuWrapper: {
    justifyContent: 'flex-start'   
  },
  menuItemsStyle: {
    fontSize: '18px',
    padding: '0.25rem 0.25rem 0 0.25rem'
  }
}

export const HeaderMenu = ({ locationState }) => {
  const { headerMenu } = useSiteMetadata()

  const desktopMenu = (
    <Navigation
      variant='horizontal'
      items={headerMenu}
      wrapperStyle={styles.desktopMenuWrapper}
      itemStyle={styles.menuItemsStyle}
    />
  )

  const mobileMenu = (
    <Drawer width='70%' locationState={locationState}>
      <Navigation
        variant='vertical'
        headingProps={{ variant: 'h3' }}
        items={[
          {
            title: 'Main Menu',
            items: headerMenu
          }
        ]}
      />
    </Drawer>
  )

  return (
    <>
      <Box sx={styles.desktopMenu}>{desktopMenu}</Box>
      <Box sx={styles.mobileMenu}>{mobileMenu}</Box>
    </>
  )
}
