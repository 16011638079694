import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
    const {site} = useStaticQuery(siteMetadataQuery);
    return site.siteMetadata;
};

const siteMetadataQuery = graphql`
    query SiteMetadataQuery {
        site {
            siteMetadata {
                address
                description
                email
                name
                phone
                title
                footerMenu {
                    items {
                        name
                        slug
                    }
                    title
                }
                headerMenu {
                    name
                    slug
                }
                social {
                    name
                    url
                }
            }
        }
    }
`;
export default useSiteMetadata;
