import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Heading } from "theme-ui"
import logo from "./logo.png"

const styles = {
  image: {
    width: "100%",
  },
  wrapImage: { 
    width: "200px", 
    padding: "11px 0 0 0" },
  grayscale: {
    WebkitFilter: `grayscale(1)`,
    filter: `grayscale(1)`,
    opacity: `0.7`,
  },
  title: {
    m: 0,
  },
}

const Logo = ({ title, grayscale, to, ...props }) => (
  <Heading
    as={Link}
    to={to}
    aria-label="Go to homepage"
    variant="h2"
    sx={styles.title}
    {...props}
  >
    <div style={styles.wrapImage}>
      <img style={styles.image} src={logo} alt="logo" />
    </div>
  </Heading>
)

export default Logo

Logo.defaultProps = {
  to: "/",
}

Logo.propTypes = {
  title: PropTypes.string,
  grayscale: PropTypes.bool,
  to: PropTypes.string,
}
