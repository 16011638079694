import React from 'react'
import { Box } from 'theme-ui'
import Navigation from '../../flow-ui-components/Navigation';
import useSiteMetadata from '@helpers/useSiteMetadata'
import { FooterData } from './FooterData'

console.log(FooterData);

const styles = {
  navHeader: {
    display: [`none`, `block`],    
    color: 'footerText',
    fontWeight: 'bold'
  }
}

export const FooterMenu = () => {
  //const { footerMenu } = useSiteMetadata()
  console.log(FooterData);
  return (
    <>
      {FooterData.map((menu, idx) => {console.log(menu); return (
        <Box key={`footer-menu-${idx}`}>
          {/*<Navigation
            variant={[`horizontal`, `vertical`]}
            headingProps={{ variant: 'h4', as: 'p', sx: styles.navHeader }}
            itemStyle={{
              marginLeft: 0,
              color: 'white'
            }}
            items={[menu]}
          />*/}
          <div>
            <p style={{color: 'red'}}> 
              {menu.title}
            </p>
            <a href={menu.items[0].name.slag}> 
              
              <div dangerouslySetInnerHTML={{__html: menu.items[0].name}} />
            </a>
          </div>
        </Box>
      )})}
    </>
  )
}
