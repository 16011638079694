import colors from './color-preset'

const opaqueLightYellow = `rgba(255, 229, 100, 0.2)`

//Primary
const alpha = {
  alphaLighter: colors.indigo[100],
  alphaLight: colors.indigo[300],
  alpha: colors.indigo[600],
  alphaDark: colors.indigo[800],
  alphaDarker: colors.indigo[900]
}

// Secondary
const beta = {
  betaLighter: colors.purple[100],
  betaLight: colors.purple[400],
  beta: colors.purple[600],
  betaDark: colors.purple[800],
  betaDarker: colors.purple[900]
}

// Reserved
const gamma = {}

// Reserved
const psi = {}

// Neutral
const omega = {
  omegaLighter: colors.gray[200],
  omegaLight: colors.gray[300],
  omega: colors.gray[500],
  omegaDark: colors.gray[600],
  omegaDarker: colors.gray[800]
}

export default {
  text: colors.gray[600],
  article: colors.gray[700],
  heading: colors.gray[800],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  successLight: colors.green[100],
  success: colors.green[600],
  errorLight: colors.red[100],
  error: colors.red[500],

  white: colors.white,
  background: `#f8f8f8`,
  contentBg: colors.white,
  headerBg: colors.white,
  footerBg: '#3b3838',
  footerText: colors.white,

  mute: colors.gray[300],
  highlight: opaqueLightYellow

  // modes: {
  //   dark: {
  //     // text: lightWhite,
  //     // background: colors.gray[800],
  //     // primary: purple30,
  //     // secondary: lightWhite,
  //     // muted: opaqueLightWhite,
  //     // highlight: purple60,
  //     // heading: white
  //   },
  // },
}
