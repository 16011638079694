import React, { useContext } from "react"
import { Container, Box, Flex } from "theme-ui"
import pageContextProvider from "@helpers/pageContextProvider"
import { HeaderLogo } from "@layout/Header/Header.Logo"
import { HeaderMenu } from "@layout/Header/Header.Menu"
import { Button } from "theme-ui"
import "./header.css"

const styles = {
  layout: {
    header: {
      py: [1, 2],
      height: ["50px", "90px"],
    },
  },
  wrapper: {
    position: `relative`,
    bg: `headerBg`,
  },
  container: {
    position: `relative`,
    zIndex: 10,
  },
  logoContainer: {
    flexBasis: [`full`, `full`, `200px`],
    marginRight: '25px',
    paddingButtom: '3px'
  },
  menuContainer: {
    minWidth: `auto`,
    flex: 1,
    height: '18px'
  },
  buttonsContainer: {
    textAlign: "right",
    display: ["none", "none", "flex"],
    justifyContent: `flex-end`,
  },
	links: {
    letstalk: {
			"text-decoration": "none"
    },
	},
  buttons: {
    display: `block`,
    mx: `auto`,
    letstalk: {
      height: "36px",
      marginRight: '5px',
      borderColor: "#c9211e",
      backgroundColor: "#c9211e",
      svg: {
        color: `alpha`,
        size: `icon.sm`,
      },
      ":hover": {
        borderColor: "#c9211e",
        backgroundColor: "#c9211e",
      },
    },

    demo: {
      height: "36px",
      color: "#c9211e",
      backgroundColor: "white",
      borderColor: "#c9211e",
      ":hover": {
        borderColor: "#c9211e",
        backgroundColor: "#c9211e",
        color: "white",
      },
    },
  },
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const locationState =
    context.location && context.location.state && context.location.state.key
  return (
    <Box sx={styles.wrapper}>
      <Container variant="compact" sx={styles.container}>
        <Flex variant="layout.header" sx={styles.layout.header}>
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>
          <Box sx={styles.menuContainer}>
            <HeaderMenu locationState={locationState} />
          </Box>
          <Box sx={styles.buttonsContainer}>
          	<a href={"/contact"} css={styles.links.letstalk}> 
							<Button className="pulse" sx={styles.buttons.letstalk}>
								Let's Talk
							</Button>
						</a>
          </Box>
        </Flex>
      </Container>
      {children}
    </Box>
  )
}
