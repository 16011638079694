export const FooterData = [
    {
      title: "",
      items: [
        {
          name: [
                          "<span style='color: red;'>Arlington, MA</span>",
                          "366 Massachusetts Ave",
                          "Suite 203",
                          "Arlington, MA 02474"
                      ].join('<br />'),
          slug: "",
        },
      ],
    },
    {
      title: "",
      items: [
        {
          name: [
                          "<span style='color: red;'>Portsmouth, NH</span>",
                          "170 Commerce Way",
                          "Suite 200",
                          "Portsmouth, NH 03801"
                      ].join('<br />'),
          slug: "",
        },
      ],
    },
    {
      title: "",
      items: [
        {
          name: [
                          "We create future proof digital",
                          "automation solutions. Tell us",
                          "about your needs, we'd love to",
                          "collaborate with you.",
                          [
                              "<div style='color: #00c2ff; border-bottom: 1px #00c2ff solid; margin-bottom: 0.5em; margin-top: none;'><a href='mailto:hello@datacollaborative.com' style='color:#00c2ff; text-decoration:unset'>hello@datacollaborative.com</a><a href='https://www.linkedin.com/company/datacollaborative'><img src='../img/linkedin.png' style='width: 20px;border-radius: 3px; margin-left: 10px;'/></a></div> ",
                              "<div style='color: #00c2ff; margin-top: 0.5em;display: flex;justify-content: space-between;'>781.777.1119 <a href='https://twitter.com/intent/tweet?screen_name=@quickbasenews'><img src='../img/twitter.png' style='width: 20px;border-radius: 3px; margin-left: 10px;'/></a></div>",
                          ].join('')
                      ].join('<br />'),
          slug: "",
        },
      ],
    },
];
