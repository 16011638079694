import React from 'react';
import { Link } from 'gatsby';
import './styles.css';

export const Copyrights = () => {
    return (
    <div className="copyrights">
        <ul className="privacy">
            <li className="privacy-item"><Link to="/secure-payments">Secure Payments</Link></li>
            <li className="privacy-item"><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
            <li className="privacy-item"><Link to="/privacy-policy">Privacy Policy</Link></li>                
        </ul>
        <span className="cr-text">© Copyright 2004-{new Date().getFullYear()}. Data Collaborative is a premier low-code, RPA, intelligent automation services provider and consistently the most awarded Quick Base services and Cloud integration provider. All rights reserved</span>
    </div>);
}
